<template>
  <div
    class="boxwrap"
    ref="boxwrap"
  >
    <slot></slot>
  </div>
</template>

<script>
//= mixins
import { ready } from '@/assets/js/mixins/base/Ready'
import { getApp } from '@/assets/js/mixins/common/GetApp'

export default {
  name: 'boxwrap',

  provide () {
    const boxwrapConfig = this.boxwrapConfig
    return { boxwrapConfig }
  },

  data () {
    return {
      boxwrapConfig: {
        boxwrap_h: 0,
        boxwrap_w: 0,
        header_h: 0,
        toolbar_h: 0,
        contents_h: 0
      }
    }
  },

  mixins: [ ready, getApp ],

  watch: {
    'getApp.drawer': function () {
      this.getBoxwrapWidth()
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getBoxwrapWidth)
      window.addEventListener('resize', this.getBoxwrapHeight)
      this.getBoxwrapWidth()
      this.getBoxwrapHeight()
    })
  },

  methods: {
    getBoxwrapWidth () {
      this.$loaded(() => {
        if (this.$refs.boxwrap) {
          this.boxwrapConfig.boxwrap_w = this.$refs.boxwrap.clientWidth
        }
      }, 360)
    },

    getBoxwrapHeight () {
      this.$loaded(() => {
        if (this.$refs.boxwrap) {
          this.boxwrapConfig.boxwrap_h = this.$refs.boxwrap.offsetHeight
        }
      }, 5000)
    }
  }
}
</script>
